<template>
  <div class="container">
    <div class="row mb-3 margin-top-0421">
      <div class="col-sm-12">
        <img
          class="img-fluid rounded w-100 img-thumbnail"
          :src="'/uploads/blogs/covers/' + $data.blog.image"
          :alt="$data.blog.title"
        />
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <h1 class="text-primary">{{ $data.blog.title }}</h1>
        <small>
          <i class="fa fa-clock text-secondary"></i> {{ $data.blog.created_at }} |
          <i class="fa fa-list text-secondary"></i> {{ $data.blog.name }}
        </small>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-sm-12 text-justify lead">{{ $data.blog.short_content }}</div>
    </div>

    <div class="row mt-3">
      <div class="col-sm-12 text-justify" v-html="$data.blog.content"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BlogDetail',
  data: function () {
    return {
      blog: '',
    };
  },

  async created() {
    let self = this;
    // console.log("state $store ", this.$store.state)
    let slug = this.$route.params.slug;
    let apiUrl = process.env.VUE_APP_API_URL;

    await fetch(apiUrl + 'blogs/get-detail', {
      method: 'POST',
      headers: {
        // 'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded', // application/json application/x-www-form-urlencoded multipart/form-data; boundary=something
      },
      body: 'slug=' + encodeURI(slug),
    })
      .then((response) => response.json())
      .then((resData) => {
        // console.log('Success:', resData);
        // let messages = resData.messages
        if (resData.status == 'error') {
          alert('error while geting data ' + resData.message);
          return;
        }

        self.$data.blog = resData.blog;
      })
      .catch(function (err) {
        console.log('error', err);
      });
  },
};
</script>

<style scoped></style>
